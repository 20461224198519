export const generic_backend_support_center = window.env.GENERIC_BACKEND_RAILS_SUPPORT_CENTER;
export const projects_names = window.env.GENERIC_FRONTEND_REACTJS_SUPPORT_CENTER_PROJECTS;
export const env = window.env.NODE_ENV || 'development';
export const isProd = env === 'production';

export const app_signal_monitoring_key = window.env.GENERIC_FRONTEND_REACTJS_SUPPORT_CENTER_APPSIGNAL_MONITORING_KEY || null;

export const projects_emails = window.env.GENERIC_FRONTEND_REACTJS_SUPPORT_CENTER_EMAIL_PROJECTS

export const web_support_encrypt_key = window.env.GENERIC_FRONTEND_REACTJS_SUPPORT_CENTER_ENCRYPT_KEY;
export const projects_locales = window.env.GENERIC_FRONTEND_REACTJS_SUPPORT_CENTER_LOCALES_PROJECTS;
export const projects_fonts = window.env.GENERIC_FRONTEND_REACTJS_SUPPORT_CENTER_FONT_PROJECTS.toLowerCase();

export const projects_idGa = window.env.GENERIC_FRONTEND_REACTJS_SUPPORT_CENTER_GOOGLE_ANALYTICS;