let es = {
    translation: {
        'Learn More': 'Ver más',
        'Find guides, services, samples, diagrams, best practices, tutorials, and more to learn about Nival, LLC.' : 'Encuentre guías, servicios, muestras, diagramas, mejores prácticas, tutoriales y más para aprender sobre Nival, LLC.',
        'Not found resource': 'Recurso no encontrado',
        'Terms and Conditions': 'Términos y Condiciones',
        'of use': 'de uso',
        'System error': 'Error del sistema',
        'Sorry, it seems we have an unexpected error': 'Lo sentimos, parece que ha ocurrido un error inesperado',
        'Not found recource': 'Recurso no encontrado',
        'Sorry, it seems the requested resource does not exists or is temporally off': 'Lo sentimos, parece que el recurso solicitado no existe o no está disponible temporalmente',
        'Access denied': 'Acceso denegado',
        'Sorry, it seems your user account does not have access to the requested resource': 'Lo sentimos, parece que su cuenta de usuario no tiene acceso al recurso solicitado',
        'Accept Terms and Conditions': 'Aceptar Términos y Condiciones',
        'Help and Technical Support': 'Ayuda y Soporte Técnico',
        'Help & Support': 'Ayuda & Soporte',
        'Cookies': 'Cookies',
        'Privacy': 'Privacidad',
        'Terms': 'Términos',
        'Support Center': 'Centro de Soporte',
        'English': 'Inglés',
        'Spanish': 'Español',
        'Portuguese': 'Portugués',
        'Change language': 'Cambiar idioma',
        'Light / Dark interface': 'Interfaz Clara / Oscura',
        "Search": "Buscar",
        "Support Options": "Opciones de Soporte",
        'Offline': 'Sin conexión',
        'Type': 'Toca ',
        'to search': 'para buscar',
        'Recording': 'Grabando',
        'Update available. Do you want to reload?': 'Actualización disponible. ¿Desea actualizar?',
        'Copy link': 'Copiar enlace',
        "Can't find what you're looking for?": '¿No puede encontrar lo que está buscando?',
        'Contact us': 'Contáctenos',
        'Copy': 'Copiar',
        'Copied': 'Copiado',
        'Link share failed': 'No se pudo compartir el enlace',
        'Unsupported share feature': 'Función para compartir no admitida',
        'Share link': 'Compartir enlace',
        'Send by email': 'Compartir por email',
        'Go to': 'Ir a',
        'min read': 'min lectura',
        'Search Results': 'Resultados de la búsqueda'
    }
}

export default es;
