import {Component} from 'react'
import RoutingService from "./RoutingService";
import {projects_names} from "../parameters"

class SecurityService extends Component {
    static modules = {
        errors: '/error',
    };

    static acl = {
        error500:   { path: '/error500', auth: false, namespace: 'error_pages' },
        error403:   { path: '/error403', auth: false, namespace: 'error_pages' },
        error404:   { path: '/error404', auth: false, namespace: 'error_pages' },
        
        home: { path: '/', auth: false, namespace: 'app' },
        supportById: { path: '/:id', auth: false, namespace: 'app' },
        //support: { path: '/', auth: false, namespace: 'app' },
    };

    static protectByAcl() {
        let url = null;
        let acl = SecurityService.acl;
        let id_value = null;

        for (let key in acl) {
            let path = acl[key].path

            if (path.indexOf(':id') >= 0) {
                //Tiene parámetros...
                let path_pieces = path.split('/');
                let index_of_id = -1;
                path_pieces.some((x) => { index_of_id++; return (x === ':id') });

                path_pieces = window.location.pathname.toLowerCase().split('/');
                id_value = path_pieces[index_of_id];
                path = path.replace(':id', id_value);

                if (RoutingService.urlContainsExactly(path)) {
                    url = acl[key];
                    break;
                }                
            }
            else {
                if (RoutingService.urlContainsExactly(path)) {
                    url = acl[key];
                    break;
                } 
            }
        }

        if (!url) {
            if (!id_value) id_value = "support";
            if (projects_names.toLowerCase().indexOf(id_value) < 0) id_value = "support";
            RoutingService.redirectTo(acl.error404.path, id_value);
        }
    }
}

export default SecurityService;
