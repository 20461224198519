let pt = {
     translation: {
          'Learn More': 'Ver mais',
          'Find guides, services, samples, diagrams, best practices, tutorials, and more to learn about Nival, LLC.' : 'Encontre guias, serviços, amostras, diagramas, práticas recomendadas, tutoriais e muito mais para aprender sobre a Nival, LLC.',
          'Not found resource': 'Recurso não encontrado',
          'Terms and Conditions': 'Termos e Condições',
          'of use': 'de uso',
          'System error': 'Erro no sistema',
          'Sorry, it seems we have an unexpected error': 'Desculpe, um erro inesperado parece ter ocorrido',
          'Not found recource': 'Recurso não encontrado',
          'Sorry, it seems the requested resource does not exists or is temporally off': 'Desculpe, parece que o recurso solicitado não existe ou está temporariamente indisponível',
          'Access denied': 'Acesso negado',
          'Sorry, it seems your user account does not have access to the requested resource': 'Desculpe, parece que sua conta de usuário não tem acesso ao recurso solicitado',
          'Accept Terms and Conditions': 'Aceitar Termos e Condições',
          'Help and Technical Support': 'Ajuda e Suporte Técnico',
          'Help & Support': 'Ajuda & Suporte',
          'Cookies': 'Cookies',
          'Privacy': 'Privacidad',
          'Terms': 'Termos',
          'Support Center': 'Centro de Suporte',
          'English': 'Inglês',
          'Spanish': 'Espanhol',
          'Portuguese': 'Português',
          'Change language': 'Mudar idioma',
          'Light / Dark interface': 'Interface Claro / Escuro',
          "Search": "Olhe para",
          "Support Options": "Opções de suporte",
          'Type': 'Toca ',
          'to search': 'olhe para',
          'Recording': 'Gravando',
          'Offline': 'Sem conexão',
          'Update available. Do you want to reload?': 'Atualização disponível. Você quer atualizar?',
          'Copy link': 'Link de cópia',
          "Can't find what you're looking for?": 'Não consegue encontrar o que procura?',
          'Contact us': 'Contate-nos',
          'Copy': 'Copiar',
          'Copied': 'Copiado',
          'Link share failed': 'Falha no compartilhamento de link',
          'Unsupported share feature': 'Recurso de compartilhamento não suportado',
          'Share link': 'Compartilhar link',
          'Send by email': 'Compartilhar por e-mail',
          'Go to': 'Ir para',
          'min read': 'min lidos',
          'Search results': 'Procurar resultados',
     }
}

export default pt;
