import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import RoutingService from "./Services/RoutingService";
import SecurityService from "./Services/SecurityService";
import {
  projects_names,
  projects_locales,
  projects_fonts,
  projects_emails,
} from "./parameters";

import "./i18n";

let project = null;

let email = null;

let font = null;
let allowed_locales = null;

function getProjectData() {
  let projects = projects_names.split(",");
  //let subdomains = projects_subdomains.split(",");

  let emails = projects_emails.split("||");

  let fonts = projects_fonts.split("||");
  let locales = projects_locales.split("||");

  projects.forEach((x, index) => {
    if (RoutingService.isSubdomain(x.toLowerCase())) {
      project = projects[index];
      font = fonts[index];
      allowed_locales = locales[index];
      email = emails[index];

      return;
    } else {
      project = projects[0];
      font = fonts[0];
      allowed_locales = locales[0];
      email = emails[0];
    }
  });

  return project;
}

getProjectData();
SecurityService.protectByAcl();

const root = ReactDOM.createRoot(document.getElementById("root"))

if (RoutingService.isModule(SecurityService.modules.errors)) {
  import("./ErrorPages/ErrorPages").then(({ default: ErrorPages }) => {
    root.render(
      <React.StrictMode>
        <ErrorPages project={project} />
      </React.StrictMode>      
    );
  });
} else if (project) {
  document.body.classList.add(font);
  
  import("./App/App").then(({ default: App }) => {
    root.render(
      <React.StrictMode>
        <App
          project={project}
          allowed_locales={allowed_locales}
          email={email}
        />
      </React.StrictMode>
    );
  });
} else {
  let default_subdomain = projects_names.split(",")[0];
  if (default_subdomain.indexOf("http") < 0) {
    default_subdomain = `${window.location.pathname}?subdomain=${default_subdomain}`;
  }

  RoutingService.redirectTo(default_subdomain);
}

const updateHandler = () => {
  // if (window.confirm(i18n.t("Update available. Do you want to reload?"))) {
  setTimeout(() => {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });

    window.caches.keys().then((keyList) => {
      return Promise.all(
        keyList.map((key) => {
          return window.caches.delete(key);
        })
      );
    });

    console.log("Updating content...");

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }, 100);
  // }
};

serviceWorker.register({
  onUpdate: updateHandler,
});

// serviceWorker.unregister();
