import {Component} from 'react'

class RoutingService extends Component {
    static redirectHome() {
        window.location.assign('/');
    }

    static redirectTo(path, id_param=null) {
        if (id_param != null) path = path.replace(":id", id_param);
        window.location.assign(path);
    }

    static isModule(module) {
        return (window.location.pathname.indexOf(module) >= 0);
    }

    static urlContainsExactly(path) {
        return (
            window.location.pathname === (path) ||
            window.location.pathname === (path + '/')
        );
    }

    static urlContains(path) {
        return (
            window.location.pathname.indexOf(path) >= 0
        );
    }

    static isSubdomain(path) {
        // console.log(window.location.href);
        // console.log(path);
        // console.log(window.location.href.indexOf(path));

        return (
            // HREF is used and not pathname for
            // simulating in local environments, using
            // a parameter such as: ?subdomain=traces.com
            window.location.href.indexOf(path) >= 0
        );
    }

    static setPath(path, id_param=null) {
        if (id_param != null) path = path.replace(":id", id_param);
        window.history.pushState(null, null, path);
    }

    static getParamValue(name, default_value = null) {
        let url = new URL(window.location.href);
        let value = url.searchParams.get(name);
        if (!value) return default_value;
        return value;
    }

    static getHash(default_value = null) {
        let hash = window.location.hash;
        if (!hash) return default_value;
        if (hash === '') return default_value;
        return hash.replace('#', '');
    }
}

export default RoutingService;
