import {Component} from 'react'
import {web_support_encrypt_key} from './../parameters'
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

class CookieService extends Component {
    static getVersion() {
        return 'web-support-2021-08-07';
    }

    static getCookieSessionName(name) {
        let version = CookieService.getVersion();
        if (name.indexOf('user_') === 0) return version + name;

        // Si no tiene user_, indica que son configuraciones,
        // Y se guardan con el id del usuario para que obtenga sus preferencias
        // en este navegador...
        return version + name;
    }

    static getCookie(name, default_value=null) {
        name = CookieService.getCookieSessionName(name);

        let value = localStorage.getItem(name);
        if (value == null) return default_value;

        value = CryptoAES.decrypt(value, web_support_encrypt_key);
        value = value.toString(CryptoENC);
        return value;
    }

    static setCookie(name, value, is_permanent) {
        name = CookieService.getCookieSessionName(name);

        value = CryptoAES.encrypt(value, web_support_encrypt_key);        
        localStorage.setItem(name, value);

        // Si no es permanente, se guarda una bandera indicando
        // que esta variable es de tipo session_storage...
        // Si es permanente, se elimina la bandera y queda con el funcionamiento por defecto.
        if (is_permanent) localStorage.removeItem("session_storage_" + name);
        else localStorage.setItem("session_storage_" + name, "default");
    }

    static removeCookie(name) {
        name = CookieService.getCookieSessionName(name);
        localStorage.removeItem(name);
        localStorage.removeItem("session_storage_" + name);
    }

    static clearSessionStorage() {
        let keys = Object.keys(localStorage);

        keys.forEach((key) => {
            if (localStorage.getItem("session_storage_" + key)) {
                localStorage.removeItem(key);
                localStorage.removeItem("session_storage_" + key);
            }
        });
    }

    static getLocale() {
        return CookieService.getCookie('user_locale', 'en');
    }

    static setLocale(locale) {
        CookieService.setCookie('user_locale', locale, true);
    }

    static isFirstLoad() {
        return (
            CookieService.getCookie('user_first_load') == null ||
            CookieService.getCookie('user_first_load') === '' ||
            CookieService.getCookie('user_first_load') === undefined
        );
    }

    static systemHasLoad() {
        CookieService.setCookie('user_first_load', 'true');
    }

    static getSmallAndTemporalCookie(name, default_value=null) {
        name = CookieService.getVersion() + name;

        let cookies = decodeURIComponent(document.cookie);
        cookies = cookies.split(';');
        let value = null;

        cookies.some((x) => {
            x = x.split('=');
            if (x[0].trim() === name) value = x[1];

            return (value != null);
        });

        if (value == null) return default_value;
        return value;
    }

    static setSmallAndTemporalCookie(name, value) {
        name = CookieService.getVersion() + name;

        let cookie = name+"="+value+';path=/;';
        document.cookie = cookie;
    }    
}

export default CookieService;
